import router from '@/router/index';
<!--
 * @Author: Akko
 * @Description: 用户个人信息
-->
<template>
    <div class="userinfo-container">
        <div class="info-text">个人信息表</div>
        <div class="explain-text">
            此表由主申请人填写，我们将会根据您的个人信息制作资料清单，提交后1-2个工作日内联系您。
        </div>
        <el-steps :active="$route.meta.step-1" align-center finish-status="success">
            <el-step v-for="(item,index) in titleList" :key="item.id" :title="item" @click.native="chooseStep(index)" />
        </el-steps>
        <router-view />
    </div>
</template>

<script lang="javascript">
export default {
    name: "UserInfo",
    components: {},
    data() {
        return {
            orderId: this.$store.state.user.orderId,
            titleList: [],
            giftedList: [
                "申请人信息",
                "家庭成员信息",
                "基本资料",
                "学历/专业资格",
                "工作经验",
                "其他信息",
                "完成",
            ], //优才
            expertList: [
                "申请人信息",
                "家庭成员信息",
                "基本资格",
                "学历/专业资格",
                "就业/雇主信息",
                "受聘人员职位详情",
                "完成",
            ], //专才
            studyList: [
                "申请人信息",
                "家庭成员信息",
                "基本资格",
                "学历/专业资格",
                "工作经验",
                // "主观性评价",
                "完成",
            ], //留学
            smallGiftedList: [
                "申请人信息",
                "家庭成员信息",
                "学历/专业资格",
                "工作经验",
                "完成",
            ], //优才小项目
            nowSchedule: 0, //当前已经走到的进度
        };
    },
    created() {
        // 新逻辑：判断路由是否携带5类产品id(面试到永居后续服务、办理身份证、续签到永居、续签服务【单次】、香港永居服务)
        if (this.$route.query.id) {
            this.smallGiftedList = [
                '主申请人信息',
                '受养人信息',
                '完成'
            ]
        }

        this.$store.commit("user");
        this.$store.commit("setType");
        this.nowSchedule = this.$store.state.user.baseSchedule;
        if (this.$store.state.type === "优才") {
            // 优才
            this.titleList = this.giftedList;
        } else if (this.$store.state.type === "专才") {
            // 专才
            this.titleList = this.expertList;
        } else if (this.$store.state.type === "留学") {
            // 留学
            this.titleList = this.studyList;
        } else if (this.$store.state.type === "小项目-优才") {
            // 优才小项目
            this.titleList = this.smallGiftedList;
        }
    },
    methods: {
        chooseStep(idx) {
            this.$store.commit("user");
            this.$store.commit("setType");
            this.nowSchedule = this.$store.state.user.baseSchedule;
            localStorage.setItem("stepChange", 1);
            this.$store.commit("stepChange");
            let step = idx + 1;
            if (this.nowSchedule - 1 >= idx) {
                if (this.$store.state.type === "优才") {
                    this.$router.push({
                        name: "step" + step,
                        params: {
                            id: this.$store.state.user.orderId,
                        },
                    });
                } else if (this.$store.state.type === "专才") {
                    this.$router.push({
                        name: "etep" + step,
                        params: {
                            id: this.$store.state.user.orderId,
                        },
                    });
                } else if (this.$store.state.type === "留学") {
                    this.$router.push({
                        name: "ltep" + step,
                        params: {
                            id: this.$store.state.user.orderId,
                        },
                    });
                } else {
                    // 新产品逻辑
                    if (this.$route.query.id) {
                        this.$router.push({
                            name: `SmallStep${step}`,
                            params: {
                                id: this.$store.state.user.orderId,
                            },
                            query: {
                                id: this.$route.query.id // 产品ID
                            }
                        });
                    } else {
                        this.$router.push({
                            name: "ptep" + step,
                            params: {
                                id: this.$store.state.user.orderId,
                            },
                        })
                    }
                }
            }
        },
    },
};
</script>

<style lang="less" scoped>
.userinfo-container {
    width: 960px;
    margin: 40px auto 0;
}

.info-text {
    font-size: 16px;
    margin-bottom: 8px;
    color: #303133;
    font-weight: bold;
}

.explain-text {
    font-size: 14px;
    font-weight: 500;
    color: #606266;
    margin-bottom: 32px;
    letter-spacing: 1px;
}

.el-steps {
    width: 1030px;
    margin: 0 auto 44px;
    transform: translateX(-28px);
}

/deep/.el-step__head.is-success {
    cursor: pointer;
}
</style>
